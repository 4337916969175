import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ComingSoon from './pages/extra/ComingSoon';
import Header from './common/Header';

function App() {
  return (
    <Router>
      {/* <Header></Header> */}
      <div id="page-container" className="fade show">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
        </Routes>
      </div>

    </Router>
  );
}

export default App;
